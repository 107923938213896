@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  color: theme('colors.black.300');
  background-color: theme('colors.gold.100');
}

@layer utilities {
  .folded-0 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }

  .folded-l-0.5 {
    clip-path: polygon(
      theme('spacing.2') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.2')
    );
  }

  .folded-l-1 {
    clip-path: polygon(
      theme('spacing.4') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.4')
    );
  }

  .folded-l-2 {
    clip-path: polygon(
      theme('spacing.7') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.7')
    );
  }

  .folded-l-3 {
    clip-path: polygon(
      theme('spacing.10') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.10')
    );
  }

  .folded-l-4 {
    clip-path: polygon(
      theme('spacing.12') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.12')
    );
  }

  .folded-l-5 {
    clip-path: polygon(
      theme('spacing.14') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.14')
    );
  }

  .folded-l-max {
    clip-path: polygon(
      theme('spacing.32') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.32')
    );
  }

  .folded-l-hero {
    clip-path: polygon(
      theme('spacing.64') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.64')
    );
  }

  @media screen and (max-width: theme('screens.2xl')) {
    .folded-l-hero {
      clip-path: polygon(
        theme('spacing.56') 0,
        100% 0,
        100% 100%,
        0 100%,
        0 theme('spacing.56')
      );
    }
  }

  @media screen and (max-width: theme('screens.xl')) {
    .folded-l-hero {
      clip-path: polygon(
        theme('spacing.32') 0,
        100% 0,
        100% 100%,
        0 100%,
        0 theme('spacing.32')
      );
    }
  }

  @media screen and (max-width: theme('screens.sm')) {
    .folded-l-hero {
      clip-path: polygon(
        theme('spacing.24') 0,
        100% 0,
        100% 100%,
        0 100%,
        0 theme('spacing.24')
      );
    }
  }

  .folded-r-2 {
    clip-path: polygon(
      calc(100% - theme('spacing.7')) 0,
      100% 28px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-3 {
    clip-path: polygon(
      calc(100% - theme('spacing.10')) 0,
      100% 38px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-4 {
    clip-path: polygon(
      calc(100% - theme('spacing.12')) 0,
      100% 48px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-5 {
    clip-path: polygon(
      calc(100% - theme('spacing.14')) 0,
      100% 58px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-max {
    clip-path: polygon(
      calc(100% - theme('spacing.32')) 0,
      100% theme('spacing.32'),
      100% 100%,
      0 100%,
      0 0
    );
  }

  .priority-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 80%;
    padding: 1px;
    height: 23px;
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 50%, 0 0);
    z-index: 2;
  }

  .priority-tag-exclude {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: #aaa;
    position: absolute;
    right: 0;
    top: -2px;
    text-align: center;
    text-transform: uppercase;
    max-height: 25px;
    min-width: 129px;
    border: 0;
    height: 25px;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 5% 50%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 5% 50%, 0 0);
    width: calc(80% + 1px);
    transform: scaleX(1.015);
    z-index: 1;
  }

  .printout-wrapper {
    padding: 0 !important;
    position: relative !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    height: 100vh !important;
  }

  @page {
    margin: 0cm 0cm 0cm 0cm !important;
  }

  #wrap {
    position: relative !important;
    width: 1100px !important;
    height: 100% !important;
    float: left !important;
  }

  #outer {
    position: relative !important;
    width: 1100px !important;
    height: auto !important;
    -webkit-transform-origin: top left !important;
  }

  .react-pdf__Document {
    padding: 20px;
    line-height: initial !important;
  }

  .react-pdf__Page {
    background: #ffffff !important;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    border: 1px solid #000000 !important;
  }

  .react-pdf__Page__textContent span {
    transform: scaleX(0.975898) translateY(9.47266%) !important;
  }

  .annotationLayer {
    display: hidden !important;
  }

  .break-before {
    page-break-before: always !important;
  }

  .break-after {
    page-break-after: always !important;
  }

  .break-inside-avoid {
    break-inside: avoid !important;
  }

  @media print {
    .printout-wrapper {
      max-height: none !important;
      overflow: visible !important;
    }

    #wrap {
      -webkit-transform: scale(1) !important;
      width: 1125px !important;
      height: 1300px !important;
    }

    #outer {
      -webkit-transform: scale(1) !important;
      width: 1125px !important;
      height: 1300px !important;
    }

    .request-list-printout-wrapper {
      padding: 0 !important;
      max-height: none !important;
      overflow: visible !important;
    }

    .request-list-printout {
      margin: 0 10px !important;
      border: 0 !important;
      box-shadow: 0 !important;
      width: 1125px !important;
      max-height: 1300px !important;
    }

    .print-element {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
    }

    .react-pdf__Document {
      padding: 20px !important;
      line-height: initial !important;
      margin: auto !important;
      width: 100% !important;
      height: 1300px !important;
    }

    .react-pdf__Page {
      margin: auto !important;
    }

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
      margin: 10px 0 !important;
      border: 5px solid #000000 !important;
    }

    .react-pdf__Page__textContent {
      display: none !important;
    }

    /* .react-pdf__Page__textContent span {
      transform: scaleX(0.975898) translateY(9.47266%) !important;
    } */
  }
}
