@font-face {
  font-family: 'Thumbtack Rise';
  font-weight: 400;
  src: url('/fonts/Rise/ThumbtackRise-Regular.woff2') format('woff2'),
    url('/fonts/Rise/ThumbtackRise-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Thumbtack Rise';
  font-weight: 500;
  src: url('/fonts/Rise/ThumbtackRise-Medium.woff2') format('woff2'),
    url('/fonts/Rise/ThumbtackRise-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Thumbtack Rise';
  font-weight: 800;
  src: url('/fonts/Rise/ThumbtackRise-Bold.woff2') format('woff2'),
    url('/fonts/Rise/ThumbtackRise-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Thumbtack Rise';
  font-weight: 900;
  src: url('/fonts/Rise/ThumbtackRise-Heavy.woff2') format('woff2'),
    url('/fonts/Rise/ThumbtackRise-Heavy.woff') format('woff');
}

.striped-background {
  background: no-repeat url('/images/home/background_img.png');
  background-size: contain;

  @media screen and (max-width: theme('screens.md')) {
    background-size: auto;
  }
}