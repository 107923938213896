.blog-section {
  flex: 3;
}
.dot {
  display: inline-block;
  margin-bottom: 2px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: rgb(63, 63, 63);
}

.blog-sidebar {
  flex: 1;
  padding: 0 2rem;
  z-index: 1;
  position: sticky;
  top: 100px;
}
.post-content h1 {
  font-size: 3rem;
  margin: 10px 0;
}
.post-content h2 {
  font-size: 24px;
  font-weight: 700;
  color: #100f0f;

  margin: 10px 0;
}
.post-content h3 {
  font-size: 1.5rem;
}
.post-content {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.post-content img {
  align-self: center;
  border-radius: 15px;
  margin: 10px 0;
  cursor: pointer;
}
.post-content a {
  color: #3078ef;
}
.post-content h4 {
  display: block;
  -webkit-margin-before: 1.33__qem;
  -webkit-margin-after: 1.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}
.post-content h5 {
  display: block;
  font-size: 0.83em;
  -webkit-margin-before: 1.67__qem;
  -webkit-margin-after: 1.67em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}
.post-content h6 {
  display: block;
  font-size: 0.67em;
  -webkit-margin-before: 2.33__qem;
  -webkit-margin-after: 2.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

.post-content figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}

.post-content .wp-block-image {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.post-content p {
  line-height: 1.8;
  margin: 0.5rem 0;
}
.post-content li > img {
  margin: 0 10px;
}

.has-text-align-center img {
  justify-content: center;
}
.wp-block-audio audio {
  width: 100%;
}
.post-content p.has-text-align-center {
  text-align: center;
}
.post-content ul,
menu,
dir {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}
.post-content ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}
.post-content li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.post-content ul ul,
ol ul {
  list-style-type: circle;
}
.post-content ol ol ul,
ol ul ul,
ul ol ul,
ul ul ul {
  list-style-type: square;
}
.wp-block-embed-youtube {
  display: flex;
  padding: 0 2rem;
}
.share-bottom {
  display: none;
}
.post-content iframe {
  width: 400px;
  height: 300px;
  margin: 1rem 0;
}

.author-desc {
  flex: 3;
  margin-bottom: 1rem;
}
.single-blog-img {
  min-height: auto;
  height: auto;
  align-self: flex-start;
  object-fit: contain;
}
.feature-img {
  min-height: auto;
  height: auto;
  align-self: flex-start;
  object-fit: contain;
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  background-color: rgb(0 0 0 / 50%);
  animation: overlay ease-in 1000ms;
}
.overlay-z {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  justify-content: center;
  background-color: rgb(0 0 0 / 50%);
  background-color: rgb(0 0 0 / 50%);
  padding: 0 3rem;

  animation: overlay ease-in 1000ms;
}
.overlay-z button {
  background-color: rgb(230, 92, 92);
  align-self: flex-end;
  color: #f1f1f1;
}
.overlay-img-container {
  display: flex;
  justify-content: center;
  max-height: 90vh;
}
.author-section {
  background: #f1f1f1;
}
.overlay-img-container img {
  min-height: auto;
  height: auto;
  justify-self: flex-start;
  /* object-fit: contain; */
  max-height: 85vh;
}
.wp-block-gallery.columns-1 {
  grid-template-columns: repeat(1, 1fr);
}
ul.wp-block-gallery {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
ul.wp-block-gallery > li {
  list-style: none;
  min-height: 500px;
}
ul.wp-block-gallery > li > figure > img {
  min-height: 500px;
  object-fit: cover;
}

@keyframes overlay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.blog-wrapper {
  width: 1200px;
  margin: 0 auto;
  columns: 3;
  column-gap: 4em;
}
.blog-post {
  width: 100%;
  margin: 0 0 4em;
  break-inside: avoid;
  transition: all cubic-bezier(0.42, 0, 1, 0.57) 1.2s;
}
.blog-post img {
  width: 100%;
}
.search-form {
  top: 50%;
  left: 40%;
  animation: searchForm ease-in 1500ms alternate infinite;
}
.img-container {
  max-width: 400px;
}
.search-form input {
  transition: 0.5s;
}

.search-text {
  width: 340px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 25px 0 0 25px;
}
.search-submit {
  position: relative;
  border-radius: 0 25px 25px 0;
  width: 130px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}
.search-close {
  animation: searchForm 1000ms alternate;
}
@keyframes searchForm {
  0% {
    opacity: 0;
    transform: translateY(90%);
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1050px) {
  .blog-wrapper {
    columns: 2;
    width: auto;
  }
  .overlay-img-container img {
    max-height: 600px;
  }
}
@media (max-width: 1024px) {
  .blog-wrapper {
    columns: 2;
  }
  .overlay-img-container {
    width: 100%;
    max-height: 80vh;
  }
  .overlay-img-container img {
    height: 100%;
  }
}

@media (max-width: 720px) {
  .overlay-img-container {
    width: 100%;
    max-height: 80vh;

    /* padding: 0 3rem; */
  }
  .overlay-img-container img {
    height: 100%;

    /* padding: 0 3rem; */
  }
}
@media (max-width: 768px) {
  .blog-wrapper {
    columns: 1;

    justify-content: center;
    margin: 0 auto;
  }
  .blog-img {
    max-width: 300px;
  }
  .blog-post {
    padding: 0 15px;
  }

  .img-container {
    max-width: 100vw;
    /* width: auto; */
  }
  .excerpt {
    font-size: 1rem;
  }
  .share-bottom {
    display: flex;
  }
  .search-text {
    width: 200px;
    height: 35px;
    /* width: 100%; */
  }

  ul.wp-block-gallery {
    padding: 0;
    display: grid;

    grid-template-columns: repeat(2, 250px);
    grid-auto-rows: minmax(150px, 400px);

    grid-gap: 10px;
  }
  ul.wp-block-gallery.columns-1 {
    grid-auto-rows: minmax(150px, 1fr);
  }

  ul.wp-block-gallery > li > figure > img {
    min-height: 250px;
    object-fit: cover;
  }
  .blog-container {
    padding: 1rem;
  }
}
@media (max-width: 618px) {
  ul.wp-block-gallery {
    padding: 0;
    display: grid;

    grid-template-columns: repeat(2, 240px);
    grid-auto-rows: minmax(150px, 500px);

    grid-gap: 10px;
  }
  ul.wp-block-gallery.columns-1 {
    grid-auto-rows: minmax(150px, 1fr);
  }
  ul.wp-block-gallery > li {
    margin-bottom: 1rem;
    height: auto;
  }
  ul.wp-block-gallery > li > figure > img {
    min-height: 250px;
    object-fit: cover;
  }
}

@media (max-width: 588px) {
  ul.wp-block-gallery {
    padding: 0;
    display: grid;

    grid-template-columns: repeat(2, 220px);
    grid-auto-rows: minmax(150px, 300px);

    grid-gap: 10px;
  }
  ul.wp-block-gallery > li {
    min-height: auto;
  }
}
@media (max-width: 485px) {
  ul.wp-block-gallery {
    display: grid;

    grid-template-columns: repeat(2, 180px);
    grid-auto-rows: minmax(150px, 300px);
  }
  ul.wp-block-gallery > li > figure > img {
    min-height: 200px;
    object-fit: cover;
  }
  .post-content iframe {
    width: 300px;
    height: 200px;
    margin: 1rem 0;
  }
}

@media (max-width: 400px) {
  ul.wp-block-gallery {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  ul.wp-block-gallery > li {
    min-height: auto;
  }
  ul.wp-block-gallery > li > figure > img {
    min-height: 200px;
    object-fit: cover;
  }
}
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}
